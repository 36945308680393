@charset "UTF-8";

@font-face {
    font-family: 'Didot';
    src: url('/src/fonts/Didot.ttf') format('ttf');
}

@font-face {
    font-family: 'Helvetica';
    src: url('/src/fonts/Helvetica.ttf') format('ttf');
}

/* ==============
13inchの画面
============== */
@media(max-width: 1440px) {}

/* ==============
タブレット
============== */
@media(max-width: 1366px) {
    div.header-left {
        float: left;
        padding-top: 9vh;
        width: 55%;
    }

    div.header-left h1 {
        font-family: "Didot";
        font-size: 33px;
    }

    div.header-right {
        float: right;
        width: 45%;
        padding-top: 9vh;
    }

    div.header-right>ul {
        display: flex;
        height: 3rem;
        justify-content: space-between;
    }

    div.header-right ul>li.menu {
        margin-left: 10px;
        width: 35%;
        font-size: 10px;
    }

    /* Top page */
    div.top-image-container {
        overflow: hidden;
        height: 75vh;
        margin-bottom: 2vh;
    }

    div.top-video-container iframe {
        height: 40vh;
    }

    div.photo {
        margin-bottom: 10px;
    }

    figcaption.credit {
        transition: none;
    }

    /* Credit display when mouse hover */
    div.photo-wrapper figure:hover>figcaption.credit,
    div.photography-wrapper figure:hover>figcaption.credit,
    div.videos-wrapper figure:hover>figcaption.credit {
        opacity: 0;
    }

    /* Work & Videos Pages */
    div.top-video-container {
        margin-bottom: 1.5vh;
    }

    div.videos-wrapper label.credit {
        font-size: 13px;
    }
}

/* ==============
スマートフォン
============== */
@media(max-width: 670px) {
    header {
        width: 80vw;
        display: block;
    }

    div.header-left {
        float: left;
        padding-top: 3vh;
        width: 50%;
    }

    div.header-left h1 {
        font-family: "Didot";
        font-size: 30px;
        line-height: 1;
    }

    div.header-right {
        float: left;
        width: 100%;
        padding: 3vh 0;
    }

    div.header-right>ul {
        display: flex;
        height: 2rem;
        justify-content: space-between;
    }

    div.header-right ul>li.menu {
        margin-left: 10px;
        width: 35%;
        font-size: 10px;
    }

    div.header-right ul li a img {
        height: 20px;
        margin: 0 10px 6px 10px;
    }

    div.header-right ul li ul li {
        font-size: 10px;
        float: none;
        width: 100%;
        display: inline-block;
    }

    .dropdown .dropdown-content {
        overflow: visible;
    }

    div.header-right ul li ul li:hover {
        transform: scale(1.2);
        transition: 0.15s;
    }

    /* Top page */

    div.top-image-container {
        overflow: hidden;
        height: 35vh;
        margin: 0 auto;
        margin-bottom: 1.5vw;
    }

    div.top-image-container img {
        width: 80vw;
        height: 100%;
        object-fit: cover;
    }

    div.photo-wrapper figure,
    div.photography-wrapper figure {
        margin: 0 0 5px 0;
        position: relative;
        display: flex;
        overflow: hidden;
    }

    /* Image display according to scrolling */
    /* .fade {
        transition: all 2000ms;
        opacity: 1;
        visibility: visible;
        transform: translate(0px, 0px);
    }

    .fadein {
        opacity: 1;
        visibility: visible;
        transform: translate(0px, 0px);
    } */

    /* Work & Videos pages */

    div.top-image-container {
        margin-bottom: 5px;
    }

    div.top-video-container iframe {
        width: 100%;
        height: 30vh;
    }

    div.photo {
        margin-bottom: 5px;
    }

    div.credit-wrapper p {
        font-family: Helvetica;
        font-size: 10px;
        line-height: 17px;
        margin-right: 15px;
    }


    div.page-button h2 {
        font-size: 20px;
    }

    div.contents-wrapper h2 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    div.top-video-container {
        margin-bottom: 0.3vh;
    }

    /* display smaller-font than default */
    div.videos-wrapper label.credit {
        display: block;
        font-size: 10px;
        /* transform: scale(0.8);
        transform-origin: 0 0; */
    }

    /*about-page*/
    div.about {
        font-family: Arial, Helvetica;
        background-color: #ffffff;
        width: 80%;
        margin: 0 auto;
        margin-top: 130px;
        border: solid 0.5px #ccc;
        padding: 1.5vh 2vh;
        line-height: 25px;
        font-size: 15px;
    }

    div.biography {
        width: 80%;
        margin: 0 auto;
        padding: 1.5vh 2vh;
        text-align: center;
        line-height: 20px;
        font-size: 10px;
    }


    /*footer*/
    footer {
        text-align: center;
        font-family: 'Didot';
        font-size: 7px;
        letter-spacing: 0.5px;
        padding: 3vh;
    }
}

/* ==============
小型スマートフォン iPhone5,SE
============== */
@media(max-width: 320px) {
    div.wrapper {
        width: 85vw;
        margin: 0 auto;
    }

    header {
        width: 85vw;
    }

    /* Top page */
    div.top-image-container {
        overflow: hidden;
        height: 20vh;
        position: relative;
        margin: 0 auto;
        margin-bottom: 1.5vw;
        margin-top: 15px;
    }

    div.top-image-container img {
        width: 100%;
        position: absolute;
        top: -10%;
    }
}