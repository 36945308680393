@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Sawarabi+Mincho&family=GFS+Didot&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Didot';
    src: url('/src/fonts/Didot.ttf') format('ttf');
}
@font-face {
    font-family: 'Helvetica';
    src: url('/src/fonts/Helvetica.ttf') format('ttf');
}

/* ==============
Reset CSS
============== */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

/* Overall design */

body {
    background-color: #f2efe4;
    line-height: 1;
    font-family: Didot, Helvetica;
    min-height: 100vh;
    color: #000;
}

/* Header */

header {
    width: 80vw;
    height: 23vh;
    display: flex;
}

header h1 {
    font-family: Didot;
    font-size: 38px;
    font-weight: bold;
}
.header-right>ul {
    height: 3rem;
    justify-content: flex-end;
}

.header-right ul > li.menu {
    font-family: Didot;
    width: 35%;
    margin-left: 10px;
    padding-right: 20px;
    text-align: center;
    letter-spacing: 1px;
}

.header-right ul>li.menu:hover {
    transform: scale(1.2);
    transition: 0.15s;
}

/* Page content */

div.top-image-container {
    overflow: hidden;
    height: 92vh;
    margin-bottom: 20px;
}

div.photo-page-wrapper {
    overflow: hidden;
    width: 100%;
}

div.photo-wrapper,
div.photography-wrapper,
div.videos-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

div.col {
    width: 49%;
}

/* div.col a.photo,
div.col a.video {
    display: block;
    width: 100%;
} */

div.col img {
    width: 100%;
}

div.photo-wrapper figure,
div.photography-wrapper figure {
    margin: 0 0 20px 0;
    position: relative;
    display: flex;
    overflow: hidden;
}

div.videos-wrapper figure {
    margin: 0.3rem 0 1rem 0;
    display: flex;
    overflow: hidden;
}

figcaption.credit {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    display: flex;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    line-height: 13px;
    font-family: Helvetica;
    font-size: 10px;
    color: #ffffff;
    text-align: left;

    opacity: 0;
    transition: all 0.6s ease;
}

figcaption.credit p {
    margin: 20px 0 0 20px;
    display: inline-block;
    width: 35%;
    white-space: pre-wrap;
}

label.credit {
    font-size: 15px;
    white-space: pre-wrap;
}

/* Credit display when mouse hover */
div.photo-wrapper figure:hover>figcaption.credit,
div.photography-wrapper figure:hover>figcaption.credit {
    opacity: 1;
}

div.photo-wrapper figure:hover>img,
div.photography-wrapper figure:hover>img,
div.videos-wrapper figure:hover>img {
    transform: scale(1.1);
    transition: 0.5s;
}

/* Work pages */
div.contents-wrapper h2 {
    font-size: 25px;
    margin-bottom: 5px;
    line-height: 1;
    font-weight: bold;
}

div.top-image-container,
div.top-video-container {
    margin-bottom: 3vh;
}

div.top-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.top-video-container iframe {
    height: 540px;
}

div.photo {
    margin-bottom: 20px;
}

div.credit-wrapper {
    display: flex;
}

div.credit-wrapper p {
    font-family: Helvetica;
    font-size: 12px;
    line-height: 23px;
    margin-right: 15px;
}

div.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

div.page-button {
    margin: 60px 0;
}

div.page-button h2 {
    font-size: 40px;
}

div.page-button a {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    text-decoration: none;
    opacity: 0.7;
}

div.page-button a:hover {
    opacity: 1;
}

div.triangle {
    height: 20px;
}

div.pre-triangle {
    width: 0;
    height: 0;
    border-top: solid 10px transparent;
    border-left: solid 15px transparent;
    border-bottom: solid 10px transparent;
    border-right: solid 15px #000000;
    margin-right: 10px;
}

div.next-triangle {
    width: 0;
    height: 0;
    border-top: solid 10px transparent;
    border-right: solid 15px transparent;
    border-bottom: solid 10px transparent;
    border-left: solid 15px #000000;
    margin-left: 10px;
}

/* Footer */

footer {
    font-family: Didot;
    padding: 50px;
    letter-spacing: 0.5px;
    text-align: right;
    margin-top: auto;
}